import React from "react";

export const Contact = () => {
  return (
    <form
      className="contacts-form"
      method="post"
      action="https://www.flexyform.com/f/2e2c45b5dcaa93ccbad297f7cc2cdc3a5d3d5dfd"
    >
      <label className="contacts-form__item">
        {/* Ваше ім'я* */}
        <input
          type="text"
          name="name"
          className="contacts-form__item_input"
          required
          placeholder="Ваше ім'я*"
          aria-label="Ваше ім'я"
        />
      </label>
      <label className="contacts-form__item">
        {/* Поштова скринька/Email* */}
        <input
          type="email"
          name="email"
          className="contacts-form__item_input"
          required
          placeholder="Поштова скринька/Email*"
          aria-label="Поштова скринька/Email"
        />
      </label>
      <label className="contacts-form__item">
        {/* Ваше повідомлення */}
        <textarea
          name="message"
          className="contacts-form__item_textarea"
          placeholder="Ваше повідомлення"
          aria-label="Ваше повідомлення"
        />
      </label>
      <button
        type="submit"
        className="contacts-form__item contacts-form__item_submit"
        aria-label="Надіслати"
      >
        Надіслати
      </button>
    </form>
  );
};
